import { css } from "@emotion/react";

export const containerCss = css`
  margin: 1rem;
`

export const sortCss = css`
  display: flex;
  gap: 0.5rem;
`
