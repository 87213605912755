import { css } from "@emotion/react";

export const containerCss = css`
  display: flex;
  gap: 0.625rem;
  /* padding-left: 0.5rem; */
`

export const contentCss = css`

`